import {createContext, Dispatch, useContext, useState} from "react";
import {CrowdAnalyticsFilter, PeopleSource} from "../../graphql/types";




const Context = createContext<[CrowdAnalyticsFilter, Dispatch<CrowdAnalyticsFilter>]>([{source: []}, (state: CrowdAnalyticsFilter) => {}]);

export function AnalyticsFilterProvider({ children }) {
  const [filter, setFilter] = useState({
    source: Object.values(PeopleSource),
  } as CrowdAnalyticsFilter)
  return (
    <Context.Provider value={[filter, setFilter]}>{children}</Context.Provider>
  );
}

export function useAnalyticsFilterContext() {
  return useContext(Context);
}
