import '../../scripts/wdyr';
import React, { useState, useEffect } from 'react';
import { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import Head from 'next/head';
import NProgress from 'nprogress';
import Router from 'next/router';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enPage from '../../public/locales/en/page.json';
import enComponents from '../../public/locales/en/components.json';
import dePage from '../../public/locales/de/page.json';
import deComponents from '../../public/locales/de/components.json';

import 'antd/dist/antd.css';
import 'nprogress/nprogress.css';
import '../styles/main.scss';
import client from '../../graphql/apollo-client';
import ClientOnly from '../components/ClientOnly';
import {AnalyticsFilterProvider} from "../context/analyticsContext";

const PartyMateApp = ({ Component, pageProps }: AppProps) => {
  const [windowLanguage, setWindowLanguage] = useState('en');

  useEffect(() => {
    const handleRouteStart = () => {
      NProgress.settings.showSpinner = false;
      NProgress.start();
    };
    const handleRouteDone = () => NProgress.done();

    Router.events.on('routeChangeStart', handleRouteStart);
    Router.events.on('routeChangeComplete', handleRouteDone);
    Router.events.on('routeChangeError', handleRouteDone);

    return () => {
      Router.events.off('routeChangeStart', handleRouteStart);
      Router.events.off('routeChangeComplete', handleRouteDone);
      Router.events.off('routeChangeError', handleRouteDone);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== undefined) {
      setWindowLanguage(localStorage.getItem('lng') || navigator.language || 'en');
    }
  }, []);

  i18next.use(initReactI18next).init({
    resources: {
      en: {
        page: enPage,
        components: enComponents,
      },
      de: {
        page: dePage,
        components: deComponents,
      },
    },
    lng: windowLanguage,
  });

  return (
    <ClientOnly>
      <AnalyticsFilterProvider>
        <ApolloProvider client={client}>
          <Head>
            <title>Partymate Pro{process.env.WEB_TITLE ? ` ${process.env.WEB_TITLE}` : ''}</title>
            <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1" />
            <link rel="shortcut icon" href="/logo-small.svg" />
          </Head>
          <Component suppressHydrationWarning {...pageProps} />
        </ApolloProvider>
      </AnalyticsFilterProvider>
    </ClientOnly>
  );
};

export default PartyMateApp;
