import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_BACKEND_ENV === 'production' ? 'https://graphql.partymate.io' : 'https://graphql-staging.partymate.io/',
  headers: {
    'api-key': process.env.NEXT_PUBLIC_BACKEND_ENV === 'production' ? '1bba9def-459d-4185-a6c1-5e517277c8db' : 'ff9511e2-662d-49d1-a82f-2cbea083d4f9',
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default apolloClient;
